/* This contains only the style required to make the first panel work */
/* All other styles are considered non-critical and are included in style.sass */

@import "./src/assets/icomoon/style";

@import "bootstrap-critical";
@import "variables";
@import "fonts";

@import "info";

#app {
  h2 {
    // Section title
    font-size: 2.8rem;
    letter-spacing: $headings-spacing * 0.4; }
  ::selection {
    background: $selection; }

  #wrapper {
    position: relative;
    perspective: 1.01px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth; } }
