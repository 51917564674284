@import "variables";
$theme-colors: (primary: $prim, danger: $danger );

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/grid';

// Required pieces of reboot.sass needed to display the first panel
*,
*::before,
*::after {
  box-sizing: border-box; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: $font-family-base;
  @include font-size($font-size-base);
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $body-color;
  text-align: left;
  background-color: $body-bg; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom; }
a {
  color: #2f2d3c;
  text-decoration: none;
  background-color: transparent; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

// Required pieces of utilities.sass needed to display the first panel
.text-white {
  color: #fff !important; }

.justify-content-center {
  justify-content: center !important; }

.text-center {
  text-align: center !important; }

.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important; }

.mt-5, .my-5 {
    margin-top: 3rem !important; }

.font-weight-bold {
    font-weight: 700 !important; }

.font-weight-light {
  font-weight: 300 !important; }

.position-relative {
  position: relative !important; }
